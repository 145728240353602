import React from 'react';
import { RichTextField } from '../Prismic-elements';
import ReactPlayer from "react-player"
import styles from './Slices.module.scss';

/**
 * Text section slice component
 */
const Embed = ({ slice }) => {
  return (
  	<React.Fragment>
      <div className={`${styles.player_wrapper} ${styles.bottommargin} ${styles.topmargin}`}>
        <ReactPlayer
          className={styles.react_player}
          url={slice.primary.embedcode.embed_url }
          width='100%'
          height='100%'
          controls='true'
        />
      </div>

</React.Fragment>


  );
};

export default Embed;
