import React from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';
import {Link as RouterLink} from 'react-router-dom';
import {linkResolver} from '../../prismic-configuration';


const Tag =(props)=>{	
return(	
		<p className="">{RichText.asText(props.title,linkResolver)}</p>
	)

}
export default Tag;