import React, { useEffect,useContext } from 'react';
import {linkResolver} from '../prismic-configuration';
import {Link as Prismiclink,RichText, Date} from 'prismic-reactjs';
import { Link } from 'react-router-dom'
import { store, ACTIONS } from '../Store.js';


import Item from '../components/Item/ItemSingleview'
import CloseButton from '../components/MenuButton/CloseButton'
import BackButton from '../components/MenuButton/BackButton'


import ItemRow from '../components/Item/ItemRow'
import ItemRowRealted from '../components/Item/ItemRowRelated'



import SliceZone  from '../components/SliceZone';

 import { getColor } from '../helpers'
 import { getTintedColor } from '../helpers'


import styles from './Page.module.scss';



const InsertPage=(props)=>{

/*
const { 
    node:{
      title,
      subjects,
      image,
      year_of_publication,
      _meta,
    }
  }=props;
*/

// FOOTERCOLOR
const globalState = useContext(store);
const { dispatch } = globalState;
let color;

const {data}=props;

const [doc, setDoc] = React.useState();


// sort and filter data
  useEffect(() => {
    let result = props.data.find(obj => {
      return obj.node._meta.id === props.match.params.uid
    })
    if(result){
      result.node.subjects.map((subject,index)=>{
        if(subject.subject!== null){
          color=getTintedColor(subject.subject.title[0].text)
        }
      })  
      dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:color} })
    }
    setDoc(result)
  }, [data]);

  window.scroll(0, 0);

if(doc){

return (
	<div className={`container  mx-auto singleviewcontainer ${styles.insert_page_wrapper}`} >
     {/* <CloseButton state={props.location.state}/>*/}
      <BackButton state={props.location.state}/>
      <div className="mx-auto  container" > 
            <SliceZone sliceZone={doc.node} />
                                <div className="grid">

                    <div className="md:col-start-4 md:col-span-9">

            <h2  className= ' '>Details</h2>
            
</div>
</div>

    <div className= {styles.insert_table_wrapper} > 

    {!!doc.node.subjects[0] &&
          <React.Fragment>
          {doc.node.subjects.map((subject,index)=>(subject.subject!== null)?<ItemRow key={index} title="Subject:" value={subject.subject.title[0].text} />  : <div></div>)}
          </React.Fragment>
        } 


      { !!doc.node.year_of_publication &&  
      <ItemRow title="Year:" value={doc.node.year_of_publication} />
      }


      { typeof(doc.node.related_content_group) !== "undefined" && doc.node.related_content_group !== null && doc.node.related_content_group.length > 0  && doc.node.related_content_group[0].related_content_link!==null &&
        
         <React.Fragment>
          <ItemRowRealted title="Related Content:" realted_content_group={doc.node.related_content_group} /> 
        </React.Fragment>

        }



      </div>

      </div>

  </div>
  )

}else{
return (
		<div>
			:( no data
        </div>
		)
}
}

export default InsertPage;
