let colors= {
		"DesignResearch":"#A0D27D",
		"MoholyNagyBauhausNewBauhaus":"#78C8E1",
		"EthicsTechnologySocialDesign":"#E6D250",
		"DesignEpistemologyComplexity":"#F5B9C3",
		"DesignEducation":"#B4A5D2",
		"Landscape":"#91CDBE",
		"DesignHistory":"#F0B978"
}

let tintedcolors= {
		"DesignResearch":"#EEF7E8",
		"MoholyNagyBauhausNewBauhaus":"#E8F3F7",
		"EthicsTechnologySocialDesign":"#FAF7E1",
		"DesignEpistemologyComplexity":"#FCF1F2",
		"DesignEducation":"#F2F0F7",
		"Landscape":"#EBF6F3",
		"DesignHistory":"#FBF2E6"
}


export function getColor(incolor) {
	let color="#A3D27F"
	switch(incolor){
		case "Moholy-Nagy, Bauhaus, New Bauhaus, HfG Ulm":
			color=colors.MoholyNagyBauhausNewBauhaus;
		break;

		case "Landscape Studies":
			color=colors.Landscape;

		break;
	
		case "Design Theory, Epistemology, Methodology, Complexity":
			color=colors.DesignEpistemologyComplexity;
		break;

		case "Design Research, Project‑Grounded Research":
			color=colors.DesignResearch;
		break;

		case "Design Education":
			color=colors.DesignEducation;
		break;

		case "Design History":
			color=colors.DesignHistory;
		break;

		case "Design Ethics, Social Design":
			color=colors.EthicsTechnologySocialDesign;
		break;	
	}
	return color
}



export function getOffset(inOffset) {
	let offset=0;
	switch(inOffset){
		case "Moholy-Nagy, Bauhaus, New Bauhaus, HfG Ulm":
				offset=0;
		break;

		case "Landscape Studies":
				offset=100;

		break;
	
		case "Design Theory, Epistemology, Methodology, Complexity":
				offset=200;
		break;

		case "Design Research, Project‑Grounded Research":
				offset=300;
		break;

		case "Design Education":
				offset=400;
		break;

		case "Design History":
				offset=500;
		break;

		case "Design Ethics, Social Design":
				offset=600;
		break;	
	}
	return 	offset;

}

export function getOffsetPercent(inOffset) {
	let offset=0;


	switch(inOffset){
		case "Moholy-Nagy, Bauhaus, New Bauhaus, HfG Ulm":
				offset=0;
		break;

		case "Landscape Studies":
				offset="8.33%";
		break;
	
		case "Design Theory, Epistemology, Methodology, Complexity":
				offset="16.66%";
		break;

		case "Design Research, Project‑Grounded Research":
				offset="25%";
		break;

		case "Design Education":
				offset="33.33%";
		break;

		case "Design History":
				offset="41.66%";
		break;

		case "Design Ethics, Social Design":
				offset="50%";
		break;	
	}
	return 	offset;

}




export function getTintedColor(incolor) {
	let color="#A3D27F"
	switch(incolor){
		case "Moholy-Nagy, Bauhaus, New Bauhaus, HfG Ulm":
			color=tintedcolors.MoholyNagyBauhausNewBauhaus;
		break;

		case "Landscape Studies":
			color=tintedcolors.Landscape;

		break;
	
		case "Design Theory, Epistemology, Methodology, Complexity":
			color=tintedcolors.DesignEpistemologyComplexity;
		break;

		case "Design Research, Project‑Grounded Research":
			color=tintedcolors.DesignResearch;
		break;

		case "Design Education":
			color=tintedcolors.DesignEducation;
		break;

		case "Design History":
			color=tintedcolors.DesignHistory;
		break;

		case "Design Ethics, Social Design":
			color=tintedcolors.EthicsTechnologySocialDesign;
		break;	
	}
	return color

}