// store.js
import React, {createContext, useReducer} from 'react';

const initialState = {
  color:'#F2F2F2',
  scrollposition:0,
  footercolor:'rgba(232,232,232,0.5)',
};
const store = createContext(initialState);
const { Provider } = store;



 const ACTIONS = {
  CHANGE_COLOR:'change color',
  SET_SCROLLPOS:'set scrollposition',
  CHANGE_FOOTERCOLOR:'set footer',
}



const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case ACTIONS.CHANGE_COLOR:
        return {...state,color:action.payload.color}// do something with the action
      break;  
      case ACTIONS.CHANGE_FOOTERCOLOR:
        return {...state,footercolor:action.payload.footercolor}// do something with the action
      break;  
      case ACTIONS.SET_SCROLLPOS:
        return {...state,scrollposition:action.payload.scrollposition}// do something with the action
      break;
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider, ACTIONS }