import React from 'react';
//import {ReactComponent as MagnifierComp} from '../../assets/SVG/18px/808080/uniE001.svg';
import {ReactComponent as MagnifierComp} from '../../assets/SVG/27_Pixel/filter_large-colored.svg';

import styles from './MenuButton.module.scss';


const FilterButton =(props)=>{

	return(
		<div>
		<button  className=  {`${styles.link} ${styles.filterbutton} ${styles.red}`} onMouseDown={props.handleMouseDown}><MagnifierComp className={`${styles.icon} ${styles.big} `}/></button>
		</div>
	)

}

export default FilterButton;
