import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Page not found (404) componenet
 */
const Test = () => {
  return (
    <div className="gridWrapper" >
      <h1 className="key">404</h1>
      <h2 className="value">Document not found</h2>
      <p><Link to="/">Return to homepage</Link></p>
    </div>
  )
}



export default Test
