// GraphQL
import { PrismicLink } from "apollo-link-prismic";
import { Link } from 'react-router-dom';

import { InMemoryCache,IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import gql from "graphql-tag";
import React from "react"


import fragmentTypes from './fragmentTypes.json';



// -- Prismic API endpoint
// Determines which repository to query and fetch data from
//export const apiEndpoint = 'https://miflckfindelitest.prismic.io/graphql'
//export const apiEndpointHelmet = 'https://miflckfindelitest.cdn.prismic.io/api/v2'

export const apiEndpoint = 'https://idralainfindeli.prismic.io/graphql'
//export const apiEndpointHelmet = 'https://idralainfindeli.cdn.prismic.io/api/v2'






// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
const accessToken = ''








const fragmentMatcher = new IntrospectionFragmentMatcher(
  { introspectionQueryResultData: fragmentTypes },
);





// -- Link resolution rules
// Manages the url links to internal Prismic documents
/*export const linkResolver = (doc) => {
  if (doc.type === 'page') return `/page/${doc.uid}`
  return '/'
}*/

export function linkResolver(type, element, content, children, index) {
  if (element.data.link_type === "Web") {
    return <a href={element.data.url}>{content}</a>
  }
  return (
    <Link key={element.data.id} to={element.data.uid}>
      {content}
    </Link>
  )
}



export const GETINSERTS=gql`
{
  allInserts(sortBy: year_of_publication_DESC) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        title
        image
        year_of_publication

        related_content_group {
          ... on InsertRelated_content_group {
            related_content_link {
              __typename
              _linkType
              ... on Item {
                _meta {
                  uid
                  id
                }
                title
              }
              ... on Insert {
                _meta {
                  uid
                  id
                }
                title
              }
            }
          }
        }




        subjects{
          ... on InsertSubjects{
            subject{
              _linkType
              __typename
              ...on Tag{
                _meta{
                  id
                }
                title
              }
            }
          }
        }
        
        body{
          ... on InsertBodyText{
            type
            primary{
              text
            }
          } 
          
        ... on InsertBodyImage{
          type
            primary{
              singleimage
              caption
            }
          }



... on InsertBodyGallery {
  type  
            primary {
              text
            }
            fields {
              media {
                _linkType
                ... on _FileLink {
                  name
                  url
                  size
                }
                ... on _ImageLink {
                  name
                  url
                  size
                }
              }
              caption
            }
          }









        }
        _meta {
          id
        }
      }
    }
  }
}
`


export const GETDOWNLOADS =gql`
{
  allDownloadss {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        body {
       ...on DownloadsBodyTitle{
          type
            primary{
              text
            }
          }



          ... on DownloadsBodyText {
            type
            primary {
              text
            }
          }

          ... on DownloadsBodyDownloadlink {
            type
            primary {
              link {
                _linkType
                ... on _FileLink {
                  name
                  url
                  size
                }
              }
            }
          }

          ... on DownloadsBodyGallery {
            type
           
            fields {
              id_image
              media {
                _linkType
                ... on _FileLink {
                  name
                  url
                  size
                }
                ... on _ImageLink {
                  name
                  url
                  size
                }
              }
              caption
            }
          }
        }
        _meta {
          id
        }
      }
    }
  }
}
`



export const GETDATA = gql`
		{
  allItems (sortBy:year_of_publication_DESC){
    pageInfo{
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        title
        bibid
        author
        coauthors
        keywords

        related_content_group {
          ... on ItemRelated_content_group {
            related_content_link {
              __typename
              _linkType
              ... on Item {
                _meta {
                  id
                  uid
                }
                title
              }
              ... on Insert {
                _meta {
                  id
                  uid
                }
                title
              }
            }
          }
        }

        subjects {
          ... on ItemSubjects {
            subject {
              _linkType
              __typename
            }
            subject {
              ... on Tag {
                 _meta {
                  id
                }
                title
              }
            }
          }
        }
        language
        publisher
        typology
        title_of_publication
        ort
        datum
        editors
        volume
        issue
        short_title
        pages
        place_of_publication
        copyright
        issn
        isbn
        year_of_publication
        doi
        link_to_the_publisher
        type_of_link
        nebisLink
        notes
        ready_for_publishing
        main_media {
          ... on _FileLink {
            name
            url
            size
          }
        }
        _meta {
          id
        }
      }
    }
  }
}
    `



export const GETNEXTDATA = gql`
query DOCS($after: String!) {
  allItems (after:$after, sortBy:year_of_publication_DESC){
    pageInfo{
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        title
        bibid
        author
        coauthors
        keywords
        related_content_group {
          ... on ItemRelated_content_group {
            related_content_link {
              __typename
              _linkType
              ... on Item {
                _meta {
                  id
                  uid
                }
                title
              }
              ... on Insert {
                _meta {
                  id
                  uid
                }
                title
              }
            }
          }
        }


        subjects {
          ... on ItemSubjects {
            subject {
              _linkType
              __typename
            }
            subject {
              ... on Tag {
                 _meta {
                  id
                }
                title
              }
            }
          }
        }
        language
        publisher
        typology
        title_of_publication
        ort
        datum
        editors
        volume
        issue
        short_title
        pages
        place_of_publication
        copyright
        issn
        isbn
        year_of_publication
        doi
        link_to_the_publisher
        type_of_link
        nebisLink
        notes
        ready_for_publishing
        main_media {
          ... on _FileLink {
            name
            url
            size
          }
        }
        _meta {
          id
        }
      }
    }
  }
}
    `


export const GETSINGLEDATA = gql`
query DOCS($id: String!) {
  allItems (id:$id){
    pageInfo{
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        title
        bibid
        author
        coauthors
        keywords
        subjects {
          ... on ItemSubjects {
            subject {
              _linkType
              __typename
            }
            subject {
              ... on Tag {
                 _meta {
                  id
                }
                title
              }
            }
          }
        }
        language
        publisher
        typology
        title_of_publication
        ort
        datum
        editors
        volume
        issue
        short_title
        pages
        place_of_publication
        copyright
        issn
        isbn
        year_of_publication
        doi
        link_to_the_publisher
        type_of_link
        nebisLink
        notes
        ready_for_publishing
        main_media {
          ... on _FileLink {
            name
            url
            size
          }
        }
        _meta {
          id
        }
      }
    }
  }
}
`


export const GETPAGE = gql`
query DOCS($uid: String!) {
   allTexts(uid:$uid){
     edges{
      node{
        _meta{
          id
          uid
          type
        }
        title,
        bkgrimage,
        backgroundimage {
          _linkType
          ... on _ImageLink{
            name,
            url
          }
        },
        body{
          ... on TextBodyText{
            type
            primary{
              text
            }
          }
          ... on TextBodyEmbed{
            type
            primary{
              embedcode
            }
          }
          __typename
        }
      
      }
    }
  }
}
`
export const GETDFAULTPAGE = gql`
query DOCS($uid: String!) {
  allText_nobckgrounds(uid:$uid){
    edges{
      node{
        _meta{
          id
          uid
          type
        }
        title,
        body{
          ... on Text_nobckgroundBodyText{
            type
            primary{
              text
            }
          }
          __typename
        }
      
      }
    }
  }
}
`


export const GETMENU = gql`
{
  allMenus {
    edges {
      node {
        title
        _linkType
        menu_links {
          __typename
          ... on MenuMenu_links {
            label
            link {
              _linkType
              __typename
              
              ... on Text {
                _meta {
                  id
                  uid
                }
                title
              }
              
              ... on Insert{
                _meta {
                  id
                  uid
                }
              }

              ... on Downloads{
                _meta {
                  id
                  uid
                }
              }
              
              ... on Listpage{
                _meta {
                  id
                  uid
                }
              }
              
            }
          }
        }
      }
    }
  }
 } 
`



// graphQL	
export const client = new ApolloClient({
  link: PrismicLink({
    uri: apiEndpoint,
  }),
  cache: new InMemoryCache({ fragmentMatcher })
});

