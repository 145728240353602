import React , { useState } from 'react';
import {PrismicLink, RichText, Date} from 'prismic-reactjs';
import { RichTextField } from '../Prismic-elements';
import FsLightbox from 'fslightbox-react';
import styles from './Slices.module.scss';
import {ReactComponent as LinkIcon} from '../../assets/SVG/20_Pixel/uniE004.svg';
import {ReactComponent as LightboxIcon} from '../../assets/SVG/20_Pixel/uniE006.svg';
import DownloadLink from "react-download-link";


/**
 * Image gallery slice component
 */
const Gallery = ({ slice }) => {

  const [lightboxController, setLightboxController] = useState({ 
  toggler: false, 
  slide: 1 
  }); 

  function openLightboxOnSlide(number) { 
    setLightboxController({ 
      toggler: !lightboxController.toggler, 
      slide: number 
    }); 
  } 

  let sourceArray=[];
  let typeArray=[];

  slice.fields.map((item, index) => {
    //sourceArray=[...sourceArray,item.media.url]
    if(item.media.__typename == "_ImageLink"){
      typeArray=[...typeArray,"image"]

      // if has id image, use that for slideshow
      if(item.id_image.url!="") {
        sourceArray=[...sourceArray,item.id_image.url]
      } else{
        // use media item for slideshow
        sourceArray=[...sourceArray,item.media.url]
      }

    }
    if(item.media.__typename == "_FileLink"){

      let regex = new RegExp('[^.]+$');
      let extension = item.media.name.match(regex);
      if(extension !="mp4"){
        sourceArray=[...sourceArray,item.id_image.url]
        typeArray=[...typeArray,"image"]
      }else{
        typeArray=[...typeArray,"video"]
         sourceArray=[...sourceArray,item.media.url]
      }
    }

    // not used anymore?
    if(item.videolink != null){
      sourceArray=[...sourceArray,item.videolink.html]
      typeArray=[...typeArray,"video"]

    }
  })

  return (
    <div className={`${styles.full_collumn} ${styles.bottompadding} ${styles.toppadding}`}>
        <FsLightbox
          toggler={lightboxController.toggler} 
            sources={ sourceArray }
            slide={lightboxController.slide} 
            types={typeArray} 
        />
      <section >
          <div className={`${styles.grid_container}`}>
            {slice.fields.map((item, index) => (
              <GalleryItem item={item} key={index} index={index} openLightboxOnSlide={openLightboxOnSlide}/>
            ))}
          </div>

      </section>
    </div>
  );
};

/**
 * Gallery item component
 */
const GalleryItem = ({ item,index,openLightboxOnSlide }) => {
let  getBlobFromURL = (url) => new Promise((resolve, reject) => {
        setTimeout(() => {
            fetch(url)
              .then(function(response) {
                return response.blob()
              })
                 .then(data => {
                    resolve(data)
                });
        });
    }, 2000);

if( typeof(item.media) !== "undefined" && item.media !== null){
    let downloadlink = item.media.url//.replace(/(^\w+:|^)\/\//, '');

    let thumburl;
    let alttext="";

    if(item.id_image.url!="") {
      thumburl=item.id_image.thumb.url;
      alttext=item.id_image.alttext;
    }else{
      thumburl=item.media.url;
      alttext=item.media.alt;
    }

      let regex = new RegExp('[^.]+$');
      let extension = item.media.name.match(regex);

    return (
      <div className={styles.gallery_item}>
        <img className={styles.gallery_image}
        src={thumburl} 
        alt={alttext} 
        onClick={ () => openLightboxOnSlide(index+1) }
        />
    
      <div className={styles.caption}>
        <span className=" ">      
          <RichTextField field={item.caption} />
        </span> 
    
        {extension != "mp4" &&
        <span>
          <DownloadLink 
            style={{}}
            label= <LinkIcon className={`${styles.icon} ${styles.icon1}`}/>
            filename={item.media.name}
            exportFile={() => Promise.resolve(getBlobFromURL(downloadlink))}
          />
        </span>
        }

        <span>
          <LightboxIcon className={`${styles.icon} ${styles.icon2}`} onClick={ () => openLightboxOnSlide(index+1) }/>
        </span>
      </div>
    </div>
   );
}
};

export default Gallery;
