import React, { Fragment,useState, useEffect, useContext } from 'react'

import {client,linkResolver,GETDATA,GETNEXTDATA,GETINSERTS,GETMENU, GETDOWNLOADS} from './prismic-configuration';



import { Helmet } from 'react-helmet'
import './assets/main.css'

import './App.scss';



import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
//import { apiEndpointHelmet } from './prismic-configuration'
import { Preview, NotFound,Singleview,Page,Imprint,Defaultpage,InsertPage,DownloadPage,Test} from './pages'


import List from './components/List/List';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

import { store } from './Store.js';





/**
 * Main application componenet
 */
const App = (props) => {



const [doc, setDocData] = React.useState([]);
const [menu, setMenuData] = React.useState([]);



  React.useEffect(() => {
    // sort according to the year_of_publication      
      doc.sort((a, b) => parseFloat(b.node.year_of_publication) - parseFloat(a.node.year_of_publication));
  }, [doc]);

  const c=client.query({
    query: GETDATA
  })

  const insertQuery=client.query({
    query: GETINSERTS
  })

    const menuQuery=client.query({
    query: GETMENU
  })




  let fq = (res) => client.query({
      query: GETNEXTDATA,variables:{after:res.data.allItems.pageInfo.endCursor}
  }).then(response => {
    setDocData(doc => ([...doc, ...response.data.allItems.edges]));
    if(response.data.allItems.pageInfo.hasNextPage){
        return fq(response)
    }else{
        return Promise.resolve();
    }
  })

  // get data
  React.useEffect(() => {
    c.then(response => {
    setDocData(doc => ([...doc, ...response.data.allItems.edges]));
    if(response.data.allItems.pageInfo.hasNextPage){
          fq(response)
        }
    })
  .catch(error => {
    console.error(error);
  });
  },[])


  // get inserts
  React.useEffect(() => {
    insertQuery.then(response => {
    const d=[...doc,...response.data.allInserts.edges]
    setDocData(doc => ([...doc, ...response.data.allInserts.edges]));
    })
  .catch(error => {
    console.error(error);
  });
  },[])



  // get menu
  React.useEffect(() => {
    menuQuery.then(response => {
      setMenuData(response.data.allMenus.edges)
    //const d=[...doc,...response.data.allInserts.edges]
    //setDocData(doc => ([...doc, ...response.data.allInserts.edges]));
    })
  .catch(error => {
    console.error(error);
  });
  },[])








  const globalState = useContext(store);
  const { dispatch } = globalState;

  const htmlelement = document.querySelector("html");
      htmlelement.style.backgroundColor = globalState.state.color;

  const [searches, setSearch] = React.useState([]);
  const setSearchItem = (dataFromChild) => {
       setSearch(dataFromChild)
    }

  return (
    <Fragment>
      <Helmet>
      </Helmet>
          <BrowserRouter>

      <div className="content flex flex-col h-screen ">
        <Header menudata={menu}/>
        <main className=" flex-grow mt-15 ">
            <Switch>
              <Route path="/test" exact render ={(props)=>(<Test {...props} data={doc} key={document.location.href}/>)}/>
              <Route path="/downloads" exact render ={(props)=>(<DownloadPage {...props} data={doc} key={document.location.href}/>)}/>
              <Route exact path="/publications/:id"  render ={(props)=>(<Singleview {...props} data={doc} key={document.location.href}/> )}/>
              <Route exact path="/about"  component ={Page} />
              <Route path="/inserts/:uid" exact render ={(props)=>(<InsertPage {...props} data={doc} key={document.location.href}/>)}/>
              <Route path="/:uid" exact render ={(props)=>(<Defaultpage {...props} key={document.location.href}/>)}/>
              <Route path="/" exact render ={(props)=>(<List {...props} data={doc} setSearchItem={setSearchItem} searches={searches}key={document.location.href}/> )}/>
              <Route component={NotFound} />
            </Switch>
        </main>

        <Footer/>
      </div>
      </BrowserRouter>

    </Fragment>
  )
  
}

export default App
