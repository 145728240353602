import React, { useState } from 'react';
import SlideIn from '../SlideIn/SlideIn'


const SlideInContainer =(props)=>{
//const [visible, setVisible] = React.useState(false);

/*const toggleMenu =()=>{
setVisible(!visible)
}

const handleMouseDown =(e)=>{
	toggleMenu();
	//e.stopPropagation();
}
*/
	return(
		 <div>
		 	<SlideIn handleSlideInButton={props.handleSlideInButton} slideInVisibility={props.visible} {...props}/>	       
      </div>
		)
}

export default SlideInContainer;