
import React, { useContext, useEffect } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';
import {linkResolver} from '../../prismic-configuration';
import {Link as RouterLink,NavLink} from 'react-router-dom';

// Components
import ItemRow from './ItemRow'
import ItemRowDownload from './ItemRowDownload'
import ItemRowRealted from './ItemRowRelated'




 import { getColor } from '../../helpers'
 import { getTintedColor } from '../../helpers'
import { store, ACTIONS } from '../../Store.js';



//import LinkIcon from '../../assets/SVG/18px/808080/uniE004.svg';

import {ReactComponent as LinkIcon} from '../../assets/SVG/18px/808080/uniE004.svg';




// style
import styles from './Item.module.scss';





const ItemSingleview =(props)=>{
	const { 
		node:{
			author,
			coauthors,
			copyright,
			doi,
			editors,
			isbn,
			issn,
			issue,
			keywords,
			language,
			title,
			title_of_publication,
			link_to_the_publisher,
			nebisLink,
			notes,
			ort,
			pages,
			place_of_publication,
			publisher,
			short_title,
			volume,
			typology,
			subjects,
			topic,
			main_media,
			datum,
			year_of_publication,
			_meta,
			ready_for_publishing,
			related_content_group

		}
	}=props;


	if(related_content_group == null) {
	}





//const keywordsarray = keywords !== null ? keywords.split(';') :["no"]


let title_of_publicationtxt=title_of_publication?'\u201c'+title_of_publication+'\u201d':'';
let volumetxt=volume?"Volume: "+volume:'';
let issuetxt=issue?"Issue: "+issue:'';
let pagestxt=pages?"pp. "+pages:'';
let year_of_publicationtxt=datum?datum:year_of_publication;
let placetxt=ort?ort:place_of_publication;








let text = [typology, publisher, title_of_publicationtxt,placetxt,year_of_publicationtxt,volumetxt,issuetxt,pagestxt].filter(x => typeof x === 'string' && x.length > 0).join(", ");


const csvData = [
  ["Author", author],
  ["Co-authors", coauthors],
  ["Edited by",editors],
  ["Source",text],
  ["Published by",publisher],
  ["Doi",doi],
  ["ISSN",issn],
  ["ISBN",isbn],
  ["Copyright",copyright],

];



  const globalState = useContext(store);
  const { dispatch } = globalState;



      useEffect(() => {
		dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:color} })
	    }, [dispatch])


let color;
subjects.map((subject,index)=>{
	if(subject.subject!== null){
		color=getTintedColor(subject.subject.title[0].text)
	}
})	
	
// hack?	
//const htmlelement = document.querySelector("html");
//      htmlelement.style.backgroundColor = color;


return(	

		<div  className={`${styles.singleViewWrapper}`}>
		<div  className={`${styles.grid}`}>

				{/*<h1 className="normal mb-8 md:mx-20">{props.node.title}</h1>*/}
				<h1 className={`${styles.title}`}>{props.node.title}</h1>

			</div>



		{ typeof(main_media) !== "undefined" && main_media !== null &&
			
			 <React.Fragment>
				<ItemRowDownload title="Download:" value="PDF" link={main_media.url} csvData={csvData} filename={props.node.title}/> 
			</React.Fragment>

			}


		


			
			{!!subjects[0] &&
				<React.Fragment>
				{subjects.map((subject,index)=>(subject.subject!== null)?<ItemRow key={index} title="Subject Area:" value={subject.subject.title[0].text} />	: <div></div>)}
				</React.Fragment>
			}	

			
			{!!language && 
				 <React.Fragment>
				<ItemRow title="Language:" value={language} /> 
				 </React.Fragment>
			}

			

		



		{!!coauthors &&
			<React.Fragment>
				<ItemRow title="Co-authors:" value={coauthors} />
			</React.Fragment>
		}
		


			<React.Fragment>
				<ItemRow title="Source:" value={text} />
			</React.Fragment>

	


	{  publisher!== "" &&	
		<ItemRow title="Published by:" link={link_to_the_publisher} value={publisher} />
	}




		{ !!editors &&	
		<ItemRow title="Edited by:" value={editors} />
		}

		{!!doi &&	
			<ItemRow title="Doi:" value={doi} />
		}
		{!!issn &&	
			<ItemRow title="ISSN:" value={issn} />
		}
		{!!isbn &&	
			<ItemRow title="ISBN:" value={isbn} />
		}
		{!!copyright &&	
			<ItemRow title="Copyright:" value={copyright} />
		}

{/*
<div className="grid grid-cols-6 gap-2 md:grid-cols-12 md:gap-5 mb-8 border">

				<div className=" background bg-red-300">1</div>
				<div className=" background bg-red-300">2</div>
				<div className=" background bg-red-300">3</div>
				<div className=" background bg-red-300">4</div>
				<div className=" background bg-red-300">5</div>
				<div className=" background bg-red-300">6</div>
				<div className=" background bg-red-300">7</div>
				<div className=" background bg-red-300">8</div>
				<div className=" background bg-red-300">9</div>
				<div className=" background bg-red-300">10</div>
				<div className=" background bg-red-300">11</div>
				<div className=" background bg-red-300">12</div>



			</div>
*/}




	


		{ typeof(related_content_group) !== "undefined" && related_content_group !== null && related_content_group.length > 0  && related_content_group[0].related_content_link!==null &&
			
			 <React.Fragment>
				<ItemRowRealted title="Related Content:" realted_content_group={related_content_group} /> 
			</React.Fragment>

			}



	</div>




	)

}
export default ItemSingleview;