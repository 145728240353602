import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { StateProvider } from './Store.js';


//import './index.css'

/*ReactDOM.render(
  <App />,
  document.getElementById('root')
)
*/


const app = (
  <StateProvider>
    <App />
  </StateProvider>
);

ReactDOM.render(app, document.getElementById('root'));
