import React from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';
import {ReactComponent as LinkIcon} from '../../assets/SVG/18px/808080/uniE004.svg';

import styles from './Slices.module.scss';

/**
 * Text section slice component
 */
const DownloadLink = ({ slice }) => {


  let documentlink = slice.primary.link.url//.replace(/(^\w+:|^)\/\//, '');

  return (
  	<React.Fragment>
      <div className={`${styles.text_wrapper} ${styles.grid_container}`}>
        <div className={`${styles.flex} ${styles.text_collumn} ${styles.download_bottompadding} ${styles.toppadding}`} >

          <a href={documentlink}  target="_blank" download>
            <div className={styles.downloadlink} >
              <span className=" ">{slice.primary.link.name}</span> <span><LinkIcon className={`${styles.icon}`}/></span>
            </div>
          </a>

        </div>
      </div>
</React.Fragment>


  );
};

export default DownloadLink;
