import React from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';
import {Link as RouterLink} from 'react-router-dom';
import {linkResolver} from '../../prismic-configuration';

import {ReactComponent as LinkIcon} from '../../assets/SVG/18px/808080/uniE003.svg';

// style
import styles from './Item.module.scss';



const ItemRow =(props)=>{
	{/*<div  className="ItemRow flex flex-row pt-305 pb-4 border-t border-gray-300 ">*/}

	
return(	

		<div  className={`${styles.grid} ${styles.itemRowWrapper}`}>
		<div  className={`${styles.key}`}>
			{props.title}
		</div>
		<div  className={`${styles.value}`}>
			{props.link?<a href={props.link} target="_blank"><div className={`${styles.link}`} ><span className=" ">{props.value}</span> <span><LinkIcon className={`${styles.icon}`}/></span></div></a>:props.value}
		</div>
	</div>

	)

}

{/* 			{props.link?<RouterLink to={props.link} target="_blank">{props.value}</RouterLink>:props.value}
// */}
export default ItemRow;



