import React, { useContext } from 'react';
import {Link} from 'react-router-dom';
//import Logo from './HKB_Markenzeichen_Kooperation_RGB_Schwarz.svg';
import {ReactComponent as Logo} from './HKB_Markenzeichen_Kooperation_RGB_Schwarz.svg';


import { store,ACTIONS } from '../../Store.js';


const Footer =(props)=>{
	{/*<div  className="ItemRow flex flex-row pt-305 pb-4 border-t border-gray-300 ">*/}


  const globalState = useContext(store);
  const { dispatch } = globalState;
 // dispatch({ type: 'action description' })
	
return(


<div className=" pt-11 md:pt-18"style={{
        backgroundColor: globalState.state.color,
    }} >

	<div className="pt-18 pt-605"    style={{
        backgroundColor: globalState.state.footercolor,
    }}>



		<div className="container  mx-auto px-2 md:px-0" >
			<div className=" grid grid-cols-12 gap-5  text-base">

				<div className=" col-span-12 ">
					A Project by Bern University of the Arts HKB, <br />Institute of Design Research IDR 
				</div>
				<div className=" col-span-12 ">
					<Logo  className="w-1/4 md:w-1/6 -ml-1 md:-ml-2"/>
				</div>


					<div  className=" col-span-12 text-right mb-8">
					    <Link to={{
            				pathname: "/imprint-and-contact",
            				state: props.state
          				}} className="text-gray-500 hover:text-gray-600 hover:underline"> Imprint and Contact</Link>
          				 <Link to={{
            				pathname: "/legal-notices",
            				//state: props.state
          				}} className="text-gray-500 ml-4 hover:text-gray-600 hover:underline"> Legal Notices</Link>
					</div>
			</div>
		</div>
	</div>
</div>

	)

}

export default Footer;



