import React from 'react';
import { Link } from 'react-router-dom'

import {ReactComponent as Close} from '../../assets/SVG/20_Pixel/uniE002.svg';

import styles from './MenuButton.module.scss';


const BackButton =(props)=>{
	return(
		<div className="">	
	  {/*<div className="fixed z-50 top-0 right-0 pl-4 pr-4 md:pr-8 pt-4 pb-4  py-2">*/}
	  {/*<div className="fixed z-50 top-0 right-0 pl-4 pr-4 pt-4 pb-4 md:pt-8 md:pb-7 py-2">*/}

	    <Link to={{
            pathname: "/",
            state: props.state
        	}}>
	       
		<div className={`${styles.backlink}`} ><span><Close className={` ${styles.icon}`} /> </span><span className=" ">Back to Works</span> </div>
	    </Link> 
	  </div>
	)

}

export default BackButton;


