import React from 'react';
//import {ReactComponent as MagnifierComp} from '../../assets/SVG/18px/808080/uniE001.svg';
import {ReactComponent as Icon} from '../../assets/SVG/27_Pixel/back-to-top-02.svg';
import styles from './MenuButton.module.scss';



const JumpMark =(props)=>{

	return(
		<div>
		<button  className=  {`${styles.link}  ${styles.jumpmark} `}  onMouseDown={props.handleMouseDown}><Icon className={`${styles.icon} ${styles.big} `}/></button>
		</div>
	)

}

export default JumpMark;
