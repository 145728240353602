import React from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';
import {Link as RouterLink} from 'react-router-dom';
import {linkResolver} from '../../prismic-configuration';

//import {ReactComponent as LinkIcon} from '../../assets/SVG/18px/808080/uniE003.svg';
import {ReactComponent as LinkIcon} from '../../assets/SVG/18px/808080/uniE004.svg';

// style
import styles from './Item.module.scss';


import { CSVLink, CSVDownload } from "react-csv";



const ItemRowDownload =(props)=>{
	{/*<div  className="ItemRow flex flex-row pt-305 pb-4 border-t border-gray-300 ">*/}

	




return(	

		<div  className={`${styles.grid} ${styles.itemRowWrapper}`}>
		<div  className={`${styles.key}`}>
			{props.title}
		</div>
		<div  className={`${styles.value}`}>
			{props.link?<a href={props.link} target="_blank"><div className={`${styles.link}`} ><span className=" ">{props.value}</span> <span><LinkIcon className={`${styles.icon}`}/></span></div></a>:props.value}
		</div>

		<div  className={`${styles.key}`}>
		</div>

		<div  className={`${styles.value} ${styles.csvDonwload}`}>
			<div className={`${styles.link}`} ><span><CSVLink    filename={`${props.filename}.csv`}
 data={props.csvData}>Data (CSV)</CSVLink></span><span><LinkIcon className={`${styles.icon}`}/></span></div>
		</div>



	</div>

	)

}

{/* 			{props.link?<RouterLink to={props.link} target="_blank">{props.value}</RouterLink>:props.value}
// */}
export default ItemRowDownload;


