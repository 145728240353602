import React, { useState, useEffect, useContext } from 'react';
import {linkResolver} from '../../prismic-configuration';

import { Link,NavLink } from 'react-router-dom'

import Item from '../Item/Item'
import Insert from '../Insert/Insert'

import Section from '../Item/Section'
import SlideInContainer from '../SlideIn/SlideInContainer'
import Search from '../Search/Search'

import Menu from '../Menu/Menu'
import FilterButton from '../MenuButton/FilterButton';
import JumpMark from '../MenuButton/JumpMark';

import { store, ACTIONS } from '../../Store.js';



const allfields = ['author', 'coauthors','copyright','datum','doi','editors','isbn','issn','issue','keywords','language','link_to_the_publisher','nebisLink','ort','pages','place_of_publication','short_title','subject','title','title_of_publication','typology','volume','year_of_publication'];
const scrollToRef = (ref) => window.scrollTo(0, 500)   


const List=(props)=>{

const {data}=props;

const [filterdGroupedDataState, setFilterdGroupedDataState] = React.useState([]);
const [input, setInput] = useState({})
const [slideInVisibility, setslideInVisibility] = useState(false)


const globalState = useContext(store);
const { dispatch } = globalState;

useEffect(() => {
dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:"#F2F2F2"} })
dispatch({ type: ACTIONS.CHANGE_FOOTERCOLOR, payload:{footercolor:"E8E8E8"} })

}, [dispatch])


	const handleSlideInButton=()=>{
		setslideInVisibility(!slideInVisibility);

	}

	const handleJumpButton=()=>{
		window.scrollTo({
    		top:0,
    		left:0,
      		behavior: 'smooth'
  		})
	}


// Make a search
	useEffect(() => {
		const c=filterData(data,props.searches) || data
		setFilterdGroupedDataState(groupByYear(c));
			//dispatch({ type: ACTIONS.SET_SCROLLPOS, payload:{scrollposition:0} })

	}, [props.searches]);


// sort and filter data
	React.useEffect(() => {
   		data.sort((a, b) => parseFloat(b.node.year_of_publication) - parseFloat(a.node.year_of_publication));

		const c=filterData(data,props.searches) || data
		setFilterdGroupedDataState(groupByYear(c));
	}, [data]);


	React.useEffect(() => {
	// do the Scrolling!
		if(globalState.state.scrollposition==''){
			const item = document.querySelector(".restore-" + props.location.state);
	    	if (item) {
		      //	window.scroll(0, (item.offsetParent.offsetTop)-180);
		    }
		} else{
			window.scroll(0, globalState.state.scrollposition);
		}
	})



const addSearchterm=(term,fields,minYear,maxYear,type)=>{
		const filterfields = fields || allfields;
		const s={term:term,fields:filterfields,minYear:minYear,maxYear:maxYear,type:type};
		const tS=[...props.searches,s]
		props.setSearchItem(tS)
	}


	const removeSearchterm=(term)=>{
		const tS=props.searches.filter(item => item.term !== term)
		props.setSearchItem(tS)
	}


	const handleInputChange=((e)=>{
		const filterfields =  allfields;
		const s={term:e.currentTarget.value,fields:filterfields, type:'custom'};
		const tS=[...props.searches,s]
		setFilterdGroupedDataState(groupByYear(filterData(data,tS)));
	})


const getFilterdData=(data_in,searchTerm)=>{
	let r=fullObjectFilterBySearchesOr(data_in,searchTerm)
	return r;	
}

const getIntersection=(arrays)=>{
	return arrays.reduce((a, b) => a.filter(c => b.includes(c)));
}

const filterData=(data,searches)=>{
	let fillteredData=[data];
	if(searches.length>0){
			const group=searches.reduce((r,a)=>{
				r[a.type]=[...r[a.type] || [], a];
				return r;
			},{});
			let results=Object.keys(group).map(key=>getFilterdData(data,group[key]))//.reduce((a, b) => a.filter(c => b.includes(c)));
			fillteredData=results;
		}	
	const intersection=getIntersection(fillteredData) || data;
	return intersection;
}


	

	// Filterfields
	// maybe make a smart recursive thing out of it? 
	const fullObjectFilterByField=((data_in,searchKeyArray,filterfields)=>{
		return data_in.filter((obj => {
			return searchKeyArray.every(function(searchKey) {
				return Object.keys(obj.node).some((key=>{
				// arrays like title, text, repeatable
					if(Array.isArray(obj.node[key])){
						return obj.node[key].some((k)=>{
							if(k.text){
							// Prismic Titels Richtext
								return check(k.text,searchKey)
							}else{
							//Tags
								return Object.keys(k).some(s=>{
									if(filterfields.indexOf(s)>-1){
										if(typeof k[s] === 'object' && k[s] !== null){
											return Object.keys(k[s]).some(m=>{
												//dont go for typename and linktype, just get title from object
												if(m==="title"){
													return check(k[s][m][0].text,searchKey)//text.includes(searchKey);
												}
											})
										}
									}
								})
							}
						})
					}else if(typeof obj.node[key] !=='undefined' && obj.node[key] && filterfields.indexOf(key)>-1){
						return check(obj.node[key],searchKey)
					}else{
						return false;
					}
				}))
			})

		}))
	})




	// Filterfields
	// maybe make a smart recursive thing out of it? 
	const fullObjectFilterBySearches=((document,searchArray)=>{
		const filterKeys = Object.keys(searchArray);
		return document.filter((obj => {
		  	const year=parseInt(obj.node.year_of_publication,10);
			 return filterKeys.every(searchKey=>{
				const searchterm=searchArray[searchKey].term;
				const searchfields=searchArray[searchKey].fields;
				const minYear=parseInt(searchArray[searchKey].minYear);
				const maxYear=parseInt(searchArray[searchKey].maxYear);
				// return if its a yearbased thing…
				if(!isNaN(minYear)) {
					if(year>=minYear && year<=maxYear){
						return true;
					}else{
						return false;
					}
				}

				return Object.keys(obj.node).some((key=>{
				// arrays like title, text, repeatable
					if(Array.isArray(obj.node[key])){
						return obj.node[key].some((k)=>{
							if(k.text){
							// Prismic Titels Richtext
								return check(k.text,searchterm)
							}else{
							//Tags
								return Object.keys(k).some(s=>{
									if(searchfields.indexOf(s)>-1){
										if(typeof k[s] === 'object' && k[s] !== null){
											return Object.keys(k[s]).some(m=>{
												//dont go for typename and linktype, just get title from object
												if(m==="title"){
													return check(k[s][m][0].text,searchterm)//text.includes(searchKey);
												}
											})
										}
									}
								})
							}
						})
					}else if(typeof obj.node[key] !=='undefined' && obj.node[key] && searchfields.indexOf(key)>-1){
						return check(obj.node[key],searchterm)
					}else{
						return false;
					}
				}))
			})

		}))
	})



	// Filterfields
	// maybe make a smart recursive thing out of it? 
	const fullObjectFilterBySearchesOr=((document,searchArray)=>{
		const filterKeys = Object.keys(searchArray);
		return document.filter((obj => {
		  	const year=parseInt(obj.node.year_of_publication,10);
			 return filterKeys.some(searchKey=>{
				const searchterm=searchArray[searchKey].term;
				const searchfields=searchArray[searchKey].fields;
				const minYear=parseInt(searchArray[searchKey].minYear);
				const maxYear=parseInt(searchArray[searchKey].maxYear);
				// return if its a yearbased thing…
				if(!isNaN(minYear)) {
					if(year>=minYear && year<=maxYear){
						return true;
					}else{
						return false;
					}
				}

				return Object.keys(obj.node).some((key=>{
				// arrays like title, text, repeatable
					if(Array.isArray(obj.node[key])){
						return obj.node[key].some((k)=>{
							if(k.text){
							// Prismic Titels Richtext
								return check(k.text,searchterm)
							}else{
							//Tags
								return Object.keys(k).some(s=>{
									if(searchfields.indexOf(s)>-1){
										if(typeof k[s] === 'object' && k[s] !== null){
											return Object.keys(k[s]).some(m=>{
												//dont go for typename and linktype, just get title from object
												if(m==="title"){
													return check(k[s][m][0].text,searchterm)//text.includes(searchKey);
												}
											})
										}
									}
								})
							}
						})
					}else if(typeof obj.node[key] !=='undefined' && obj.node[key] && searchfields.indexOf(key)>-1){
						return check(obj.node[key],searchterm)
					}else{
						return false;
					}
				}))
			})

		}))
	})





	const check=((data,searchKey)=>{
	    return data.toString().includes(searchKey);
	});


	function groupByYear(arr) {
	  return arr.reduce((acc, cur) => {
		  	const year=parseInt(cur.node.year_of_publication,10);
		  	if(year<1991){
		  		acc["1986–1990"] = [...acc["1986–1990"] || [], cur];
		  	}
		  	else if(year >= 1991 && year<1996){
		  		acc["1991–1995"] = [...acc["1991–1995"] || [], cur];
		  	}
		  	else if(year >= 1996 && year<2001){
		  		acc["1996–2000"] = [...acc["1996–2000"] || [], cur];
		  	}
		  	else if(year >= 2001 && year<2006){
		  		acc["2001–2005"] = [...acc["2001–2005"] || [], cur];
		  	}
 			else if(year >= 2006 && year<2011){
		  		acc["2006–2010"] = [...acc["2006–2010"] || [], cur];
		  	}
			else if(year >= 2011 && year<2016){
		  		acc["2011–2015"] = [...acc["2011–2015"] || [], cur];
		  	}
 			else if(year >= 2016 && year<2021){
		  		acc["2016–2020"] = [...acc["2016–2020"] || [], cur];
		  	}
		  	else{
		  		acc[cur.node.year_of_publication] = [...acc[cur.node.year_of_publication] || [], cur];
		  	}
		    return acc;
	  }, {});
	}






if(filterdGroupedDataState){
	return (
		<React.Fragment>			
					<FilterButton handleMouseDown={handleSlideInButton}/>
					<JumpMark handleMouseDown={handleJumpButton}/>

			

		<div className="container mx-auto px-2 md:px-0">

			<div className=" mx-auto">
				<SlideInContainer handleSlideInButton={handleSlideInButton} addSearchterm={addSearchterm} handleInputChange={handleInputChange} removeSearchterm={removeSearchterm} searches={props.searches} visible={slideInVisibility} />
				
			</div>	

			<div className="container mx-auto  pb-405 md:pt-305">
				<h1 className="mt-6 pt-405 pb-405 md:pt-605 md:pb-705">Collected Works 1986–2020</h1>


      		{props.searches.length > 0 &&

			<div className= "searchbox container pt-6  md:pt-9 " >
					<div className="flex flex-wrap">
					  	{
					  		props.searches.map((search,index)=>{return <Search key={index} removeSearchterm={removeSearchterm} {...search}></Search>})
						}
					</div>
			    </div>
			}
				<div className="listWrapper">
				  <div>
				  	{
						Object.keys(filterdGroupedDataState).map(function(key, index) {
							//const listItems = filterdGroupedDataState[key].map((item,ind) => (item.node.ready_for_publishing==false || item.node.__typename=="Insert")?item.node.__typename:<Item key={item.node._meta.id} {...item} />);
							
							const listItems = filterdGroupedDataState[key].map((item,ind) => {
								// return only ready for publishing articles
								if(item.node.ready_for_publishing!=false){
									switch (item.node.__typename) {
									          case 'Insert':
									            return  <Insert key={item.node._meta.id} {...item} />;
									          case 'Item':
									            return <Item key={item.node._meta.id} {...item} />;
									        }
								}
							})
							let res = key.split(",");
							if(res[0]!=""){
								return (
									<div key={res[0]} className="container sectionWrapper" >
									<div className="stickyContainer z-0">
											{/*<div  className="sticky top-15 md:top-21  bg-gray-100 z-10 opacity-90">*/}
											<div  className="sticky top-15 bg-gray-100 z-10 opacity-90">												
											<h2 className="pt-406 pb-405 md:pt-706 md:pb-705  fw">{res[0]}</h2>	
											</div>
											{listItems}
										</div>
									</div>
					  			)
							}
						})
					}
				  </div>
		        </div>
			</div>
		</div>
		</React.Fragment>
    )
}else{
	return (
		<div className="container">
			:( no data
        </div>
	)
}




}

export default List;