import React,{ useState } from 'react';
import styles from './SlideOut.module.scss';
import {ReactComponent as Close} from '../../assets/SVG/18px/808080/light_diagonal_cross.svg';

//import Close from '../../assets/SVG/18px/f2f2f2/light_diagonal_cross.svg';
//import {ReactComponent as Close} from '../../assets/SVG/18px/808080/light_diagonal_cross.svg';



const SlideOut =(props)=>{

let slideOutPanelClasses =  `${styles.slideOutPanel} ${styles.fadeOut}`
if(props.show) {
    slideOutPanelClasses = `${styles.slideOutPanel} ${styles.open} ${styles.fadeIn}`
}
	
return(
	<div id='slideOutPanel'  className={slideOutPanelClasses}>
		<button className={styles.closeButton} onMouseDown={props.handleSlidePanelButton}><Close className={`${styles.icon} ${styles.big} `}/></button>
			{props.children}
	</div>
	)

}

export default SlideOut;