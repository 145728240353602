import React, { useContext, useState } from 'react';
import {Link} from 'react-router-dom';
import { store } from '../../Store.js';
import SlideOutContainer from '../SlideOut/SlideOutContainer'
import MenuButton from '../MenuButton/MenuButton';

import styles from './Header.module.scss';



const Header =(props)=>{
const {menudata}=props;
const globalState = useContext(store);
const { dispatch } = globalState;

const [slidePanelVisibility, setslidePanelVisibility] = useState(false)
const handleSlidePanelButton=()=>{
  setslidePanelVisibility(!slidePanelVisibility);
}


const getMenu = data =>
  data.reduce((acc, item) => {
    if(item.link._meta.uid ==="writings"){
     acc[item.label] = { link: "",thing:item.__typename };
    }else{
      acc[item.label] = { link: item.link._meta.uid,thing:item.__typename };
    }
    return acc;
  }, {});

let links=null;
if(menudata.length>0){
  let  flat=getMenu(menudata[0].node.menu_links);
  links=Object.keys(flat).map(menuLink => {
    return <Link to={{
            pathname:`/${flat[menuLink].link}`,
            state: props.state
          }} onClick={handleSlidePanelButton} className>
          <div className={styles.menu_link_text}>{menuLink} </div>
        </Link>
    })
}

	return(	
    <div className={styles.header_wrapper} 
        style={{
              backgroundColor: globalState.state.color,
              }}  
        href="/">
      <div className={`container mx-auto  ${styles.header_link_title_wrapper} `}>
        <Link to={{
        pathname: "/",
        state: props.state
        }}>
          <div className={styles.header_title_wrapper}>The Complete Works of Alain Findeli</div>
        </Link>
      </div>

      <MenuButton handleMouseDown={handleSlidePanelButton}/>



    



     <div className={`container mx-auto `}>
        <SlideOutContainer show={slidePanelVisibility} handleSlidePanelButton={handleSlidePanelButton}>
          <div className={`container mx-auto ${styles.menu_wrapper} ${styles.grid}`}>
            <div  className={`${styles.title}`}>
            {links}
            </div>
          </div>
        </SlideOutContainer>
      </div>  
    </div>
	)
}

export default Header;



