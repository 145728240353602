import React from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';
import {linkResolver} from '../../prismic-configuration';
import {Link as NavLink} from 'react-router-dom';

//import {ReactComponent as LinkIcon} from '../../assets/SVG/18px/808080/uniE003.svg';
import {ReactComponent as LinkIcon} from '../../assets/SVG/18px/808080/uniE004.svg';

// style
import styles from './Item.module.scss';





const ItemRowRelated =(props)=>{
	{/*<div  className="ItemRow flex flex-row pt-305 pb-4 border-t border-gray-300 ">*/}

	




return(	

		<div  className={`${styles.grid} ${styles.itemRowWrapper}`}>
		<div  className={`${styles.key}`}>
			{props.title}
		</div>



		{props.realted_content_group.map((content,index)=>{
		let path="";

	    switch (content.related_content_link.__typename) {
	    	case ('Insert'):
	    	 path="inserts"
	    	     break;
	    	case ('Item'):
	    	 path='publications'
	    	     break;

		}


			return(
		<div  className={`${styles.value} ${styles.relatedContent}`}>

			<NavLink  onClick={()=>{
				//dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:tintedColor} }) 
				//dispatch({ type: ACTIONS.SET_SCROLLPOS, payload:{scrollposition:window.scrollY} })

				} }
				className ={`${styles.link}`} to={{pathname:`/${path}/${content.related_content_link._meta.id}`}} >
				{content.related_content_link.title}
			</NavLink>


		</div>
		)
		})
		}
	

		



	</div>

	)

}

{/* 			{props.link?<RouterLink to={props.link} target="_blank">{props.value}</RouterLink>:props.value}
// */}
export default ItemRowRelated;


