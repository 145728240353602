import React , { useState } from 'react';
import { RichText } from 'prismic-reactjs';
import { PrismicLink, RichTextField } from '../Prismic-elements';
import FsLightbox from 'fslightbox-react';
import styles from './Slices.module.scss';


/**
 * Image highlight slice component
 */
const SingleImage = ({ slice }) => {


  const [toggler, setToggler] = useState(false);
  return( 
    <div className="  ">
      <section className="image content-section">
        <FsLightbox
            toggler={ toggler }
            sources={ [
              slice.primary.singleimage.url,
            ] }
        />


        <div className={` ${styles.bottompadding} ${styles.toppadding}`}>
          <img className={`${styles.image} `}
            src={slice.primary.singleimage.url}
            alt={slice.primary.singleimage.alt}
            onClick={ () => setToggler(!toggler) }
          />
          <div className="grid">
          <div className={`${styles.singleimage_caption}`} >
            <p>
            {slice.primary.caption}
            </p>
          </div>
          </div>
        </div>
      </section>
    </div>

  );


};
export default SingleImage;
