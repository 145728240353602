import React from 'react';
import { RichTextField } from '../Prismic-elements';
import {Link, RichText, Date} from 'prismic-reactjs';
import {linkResolver} from '../../prismic-configuration';
import { htmlSerializer } from '../../utils/prismicHelpers';
import { customLink } from '../../utils/prismicHelpers';
import styles from './Slices.module.scss';

/**
 * Text section slice component
 */
const Title = ({ slice }) => {
  return (
	<div className={styles.grid_container}>
	  	 	<div className={`${styles.text} ${styles.text_collumn} ${styles.blue}`}>
				<RichText render={slice.primary.text} htmlSerializer={htmlSerializer}/>
	    	</div>	
	</div>
  );
};

export default Title;
