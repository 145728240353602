import React, { useEffect,useContext } from 'react';
import {linkResolver,client,GETDOWNLOADS,GETINSERTS} from '../prismic-configuration';
import {Link as Prismiclink,RichText, Date} from 'prismic-reactjs';
import { Link } from 'react-router-dom'
import { store, ACTIONS } from '../Store.js';
import styles from './Page.module.scss';




import Item from '../components/Item/ItemSingleview'
import CloseButton from '../components/MenuButton/CloseButton'
import BackButton from '../components/MenuButton/BackButton'

import SliceZone  from '../components/SliceZone';

 import { getColor } from '../helpers'
 import { getTintedColor } from '../helpers'



const DownloadPage=(props)=>{

// FOOTERCOLOR
const globalState = useContext(store);
const { dispatch } = globalState;
useEffect(() => {
dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:"#F2F2F2"} })
dispatch({ type: ACTIONS.CHANGE_FOOTERCOLOR, payload:{footercolor:"E8E8E8"} })

}, [dispatch])



let color;

const {data}=props;

const [doc, setDoc] = React.useState();



const downloadsQuery=client.query({
    query:GETDOWNLOADS
})



  // get inserts
  React.useEffect(() => {
    downloadsQuery.then(response => {
      setDoc(response.data.allDownloadss.edges[0]);
    })
    .catch(error => {
      console.error(error);
    });
  },[])

  window.scrollTo({
    top:0,
    left:0,
      behavior: 'smooth',
  })

if(doc){
return (
	<div className={`mx-auto singleviewcontainer container ${styles.downloadWrapper} ${styles.wrapperMargin}`} >
      {/*<BackButton state={props.location.state}/>*/}
           <SliceZone  sliceZone={doc.node} />
  </div>
  )

}else{
return (
		<div>
			DOWNLOAD :( no data
        </div>
		)
}
}

export default DownloadPage;
