import React from 'react';
import styles from './Search.module.scss';


const Search =(props)=>{

const maxSize=15;
const label=(props.term.length<maxSize)?props.term:props.term.substring(0, maxSize)+"…";

const handleMouseDown=()=>{
	props.removeSearchterm(props.term)
}



	return(
		<div>
			<div className={styles.item} onMouseDown={ () => handleMouseDown() } >{label}
				{/*<div>{props.fields}</div>
				<div>{props.minYear}</div>
				<div>{props.maxYear}</div>*/}

				<span className="pl-2"  > &#10005;</span>
			</div>
		</div>
	)

}

export default Search;

