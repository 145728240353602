import React, { useState } from 'react';
import FilterButton from '../MenuButton/FilterButton';
import SlideOut from './SlideOut'
import styles from './SlideOut.module.scss';


const SlideOutContainer =(props)=>{
	return(
		 <div id="SlideOut" >
		 	<SlideOut handleSlidePanelButton={props.handleSlidePanelButton} {...props}/>	       
      </div>
		)
}

export default SlideOutContainer;