import React from 'react';
import {
  SingleImage,
  Embed,
  TextSection,
  Gallery,
  DownloadLink,
  Title
} from './Slices';


import styles from './Slices/Slices.module.scss';
/**
 * Prismic Slice Zone component
 */
const SliceZone = ({ sliceZone }) => (
  <div className="">
 {/*     <div className="md:col-start-4 md:col-span-9 "> */}

    {
     
      sliceZone.body.map((slice, index) => {
        switch (slice.type) {
          case ('image'):
            return <SingleImage slice={slice} key={`body-${index}`} />;
          case ('gallery'):
            return <Gallery slice={slice} key={`body-${index}`} />;
       
          case ('text'):
            return <TextSection slice={slice} key={`body-${index}`} />;
          case ('title'):
            return <Title slice={slice} key={`body-${index}`} />;
          case ('downloadlink'):
            return <DownloadLink slice={slice} key={`body-${index}`} />;
          case ('embed'):
            return <Embed slice={slice} key={`body-${index}`} />;
          default:
            return null;
        }
      })
    }
     {/*   </div> */}
  </div>
);

export default SliceZone;
