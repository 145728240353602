import React, { useState, useEffect, useContext } from 'react';
import {client,linkResolver,GETDFAULTPAGE} from '../prismic-configuration';
import gql from "graphql-tag";
import {Link as Prismiclink,RichText, Date} from 'prismic-reactjs';
import { Link,NavLink } from 'react-router-dom'
import List from '../components/List/List';
import SliceZone  from '../components/SliceZone';

import CloseButton from '../components/MenuButton/CloseButton'

import { store, ACTIONS } from '../Store.js';
import styles from './Page.module.scss';


const Defaultpage=(props)=>{

const uid = props.match.params.uid;


const c=client.query({
  query: GETDFAULTPAGE,variables:{uid:uid}
})

const [doc, setDocData] = React.useState([]);
const [notFound, toggleNotFound] = useState(false);


let bkimg="";

const globalState = useContext(store);
const { dispatch } = globalState;



    useEffect(() => {
    	dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:"#F2F2F2"} })
		dispatch({ type: ACTIONS.CHANGE_FOOTERCOLOR, payload:{footercolor:"#E8E8E8"} })
	}, [dispatch])



// get data
useEffect(() => {
	c.then(response => {
  		setDocData(response.data.allText_nobckgrounds.edges);

	})
	.catch(error => {
  		console.error(error);
	});
},[])


if(doc){


	return (
		<div>
			{/*<CloseButton state={props.location.state}/>*/}
			<div className="mx-auto bg-gray-100  " >
				<div className={`mx-auto singleviewcontainer container ${styles.defaultWrapper}`} >

					{doc.map((item,index) => (
						<SliceZone sliceZone={item.node} />
					))}
				</div>
				</div>

   		</div>
  )

}else{
	return (
		<div>
			:( no data
        </div>
		)
	}
}

export default Defaultpage;