import React, { useEffect,useContext } from 'react';
import {linkResolver} from '../prismic-configuration';
import {Link as Prismiclink,RichText, Date} from 'prismic-reactjs';
import { Link } from 'react-router-dom'
import { store, ACTIONS } from '../Store.js';


import Item from '../components/Item/ItemSingleview'
import CloseButton from '../components/MenuButton/CloseButton'
import BackButton from '../components/MenuButton/BackButton'


const Singleview=(props)=>{

var result = props.data.find(obj => {
  return obj.node._meta.id === props.match.params.id
})


  window.scroll(0, 0);


// FOOTERCOLOR
const globalState = useContext(store);
const { dispatch } = globalState;
   useEffect(() => {
    dispatch({ type: ACTIONS.CHANGE_FOOTERCOLOR, payload:{footercolor:"rgba(232,232,232,0.5)"} })
  }, [dispatch])


if(result){
return (
	<div className="container  mx-auto singleviewcontainer" >
     {/* <CloseButton state={props.location.state}/>*/}


     <BackButton state={props.location.state}/>
      <Item  {...result} {...props}/>

  </div>
  )

}else{
return (
		<div>
			:( no data
        </div>
		)
}
}

export default Singleview;
