import React, { useContext, useState, useEffect } from 'react';
import {Link, RichText, Date} from 'prismic-reactjs';
import {Link as RouterLink,NavLink} from 'react-router-dom';
import {linkResolver} from '../../prismic-configuration';
import {Container, Row, Col,Button } from 'react-bootstrap';

// state
import { store, ACTIONS } from '../../Store.js';



// constants
 import constant from '../../constants/constants.json';
 import { getColor, getTintedColor,getOffsetPercent } from '../../helpers'




// style
import styles from './Insert.module.scss';

// Components
import Tag from '../Tag/Tag'



const Insert =(props)=>{
	const { 
		node:{
			title,
			subjects,
			image,
			year_of_publication,
			_meta,
		}
	}=props;




  const globalState = useContext(store);
  const { dispatch } = globalState;




  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  {/* Performs similarly to componentDidMount in classes */}
useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 700;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
}, [isMobile]);



//const keywordsarray = keywords !== null ? keywords.split(';') :["no"]
let offset=0;
let color;
let tintedColor;
let subjectClass=0;

subjects.map((subject,index)=>{
	if(subject.subject!== null){
		color=getColor(subject.subject.title[0].text);
		tintedColor=getTintedColor(subject.subject.title[0].text);

		offset=getOffsetPercent(subject.subject.title[0].text);

	}
})	


return(	




<div  className={`${styles.itemWrapper}`}>

				<div className={`${styles.item}`} style={isMobile?{}:{marginLeft:`${offset}`}}>
					<NavLink  onClick={()=>{


						dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:tintedColor} }) 
						dispatch({ type: ACTIONS.SET_SCROLLPOS, payload:{scrollposition:window.scrollY} })
						} }
						className ={`${styles.navlink} restore-${props.node._meta.id}`} to={{pathname:`/inserts/${_meta.id}`,state:props.node._meta.id}} >
						
						<div className={`${styles.colorBar} `} style={{background:color}}></div>
						
						<div className={`${styles.yearOfPublication}`}>{props.node.year_of_publication}</div>

						<div className="">
							<h3 className={`${styles.title}`} >{props.node.title}</h3>
						</div>
						<div>
							<hr />
						</div>
						<div className={`${styles.subjectWrapper} `}>
							{
								subjects.map((subject,index)=>{
									return(subject.subject!== null)?<Tag key={(subject.subject._meta.id)?subject.subject._meta.id:index} {...subject.subject} />	: ""
								})
							}
						</div>

						<div className={`${styles.imageWrapper}`}>
						<img src={image.url} alt={image.alt} />
						</div>

	
					</NavLink>
				</div>
		</div>

					



	)

}
export default Insert;