import React, { useState, useEffect, useContext } from 'react';
import {client,linkResolver,GETPAGE} from '../prismic-configuration';
import gql from "graphql-tag";
import {Link as Prismiclink,RichText, Date} from 'prismic-reactjs';
import { Link,NavLink } from 'react-router-dom'
import List from '../components/List/List';
import SliceZone  from '../components/SliceZone';

import CloseButton from '../components/MenuButton/CloseButton'
import JumpMark from '../components/MenuButton/JumpMark';

import { store, ACTIONS } from '../Store.js';


import styles from './Page.module.scss';


const Page=(props)=>{

const uid = props.match.params.uid || props.match.url.replace(/^\/|\/$/g, '');


const c=client.query({
  query: GETPAGE,variables:{uid:uid}
})

const [doc, setDocData] = React.useState([]);
const [notFound, toggleNotFound] = useState(false);


let bkimg="";

const globalState = useContext(store);
const { dispatch } = globalState;

useEffect(() => {
dispatch({ type: ACTIONS.CHANGE_COLOR, payload:{color:"#F2F2F2"} })
dispatch({ type: ACTIONS.CHANGE_FOOTERCOLOR, payload:{footercolor:"E8E8E8"} })

}, [dispatch])


	const handleJumpButton=()=>{
		window.scrollTo({
    		top:0,
    		left:0,
      		behavior: 'smooth'
  		})
	}	

// get data
useEffect(() => {
	c.then(response => {
  		setDocData(response.data.allTexts.edges);
	})
	.catch(error => {
  		console.error(error);
	});
},[])


if(doc){

	{doc.map((item,index) => (
	bkimg=item.node.backgroundimage.url
	))}
	return (
		<div>
			<JumpMark handleMouseDown={handleJumpButton}/>
			<div className="mx-auto  fixed z-negative inset-0 bg-center bg-cover  h-screen 0" style={{backgroundImage: `url(${bkimg})`}} ></div>	





			{/*<div className="mx-auto bg-fixed block bg-center bg-cover h-screen border border-red-600 z-10" style={{backgroundImage: `url(${bkimg})`}} ></div>	*/}
				{/*			<div className="mx-auto bg-gray-100 -mt-50vh md:-mt-56 " >*/}


			<div className="mx-auto    inset-0   h-screen  " ></div>	


			<div className="mx-auto bg-gray-100 -mt-50vh md:-mt-56  " >

				{/*<div className="mx-auto  container px-4 md:px-0" > */}
				<div className={`mx-auto singleviewcontainer container ${styles.wrapperMargin} ${styles.about_wrapper}`} >

					{doc.map((item,index) => (
						<SliceZone sliceZone={item.node} />
					))}
				</div>
				</div>

   		</div>
  )

}else{
	return (
		<div>
			:( no data
        </div>
		)
	}
}

export default Page;