import React,{ useState } from 'react';

import FilterButtonBullet from '../SlideIn/FilterButtonBullet'


import {SubjectButtonsData,LanguageButtonsData,YearButtonsData} from '../../filterbuttons-config'

//import Close from '../../assets/SVG/18px/f2f2f2/light_diagonal_cross.svg';
import {ReactComponent as Close} from '../../assets/SVG/18px/808080/light_diagonal_cross.svg';
import {ReactComponent as MagnifierComp} from '../../assets/SVG/18px/808080/right_pointing_magnifying_glass.svg';



const SlideIn =(props)=>{

	let visibility="hide";
	if(props.slideInVisibility)visibility="show";

	let shadow='shadow-none';
	if(props.slideInVisibility)shadow="shadow-slidein";


	const [term, setTerm] = useState('')


	const handleInputChange=((e)=>{
		setTerm(e.currentTarget.value);
		props.handleInputChange(e);

	})

	const handleKeyDown=((e)=>{
		if (e.key === 'Enter') {
				window.scroll(0, 0);
				props.addSearchterm(e.currentTarget.value,'','','','custom');

				e.currentTarget.value="";
				props.handleSlideInButton();
   		 }
	})

	const handleAddSearchterm=(()=>{
		if(term!=''){
			props.addSearchterm(term);
		}
	})

let serachterms = props.searches.map(a => a.term);

const defaults = {
	addSearchterm:props.addSearchterm,
	removeSearchterm:props.removeSearchterm ,
	active:false
}

const makeProps = (settings) => Object.assign({}, defaults, settings);

// is term my title? then i will be active
const checkTerm=(term,serachterms)=>{
	if(serachterms.indexOf(term)>-1){
		return true
	}return false;
}


// get the buttons
const subjectbuttons=SubjectButtonsData.map((item,index)=>{
	const active=(checkTerm(item.text,serachterms))
	//const props=makeProps({active:active,bullet:item.bullet, fields:item.fields, type:item.type})
	const props=makeProps({active:active,...item})

	return<FilterButtonBullet key={index} text={item.text} {...props} ></FilterButtonBullet>
})

const languagebuttons=LanguageButtonsData.map((item,index)=>{
	const active=(checkTerm(item.searchterm,serachterms))
	const props=makeProps({active:active,...item})
	return<FilterButtonBullet key={index} text={item.text} {...props} ></FilterButtonBullet>
})

const yearbuttons=YearButtonsData.map((item,index)=>{
	const active=(checkTerm(item.text,serachterms))
	const props=makeProps({active:active,...item})
	return<FilterButtonBullet key={index} text={item.text} {...props} ></FilterButtonBullet>
})

	
	return(
		<div id='slideInPanel'  className={`bg-gray-600 ${visibility} text-base ${shadow} `}>
			<div className="mb-18 md:mb-0 ">
				<div className="flex  h-15 w-15 center-content justify-center absolute right-0 top-0">
					{/*<button className="pt-4 pb-4 md:pt-8 md:pb-7" onMouseDown={props.handleSlideInButton}><Close className="text-gray-400 hover:text-gray-300 w-6 h-6 md:w-6 m-auto md:w-6 fill-current"/></button>*/}
					<button className=" pt-4 pb-4 pr-0 md:pr-8" onMouseDown={props.handleSlideInButton}><Close className="text-gray-400 hover:text-gray-300 w-6 h-6 m-auto  fill-current"/></button>

				</div>

				<div className="mt-15 h-15 w-100 mb-11 ">
					 <input autoComplete="off" placeholder="Search" className=" rounded-none focus:outline-none placeholder-gray-400 w-full mt-6 pb-1 bg-gray-600 text-gray-100 border-b border-gray-100 px-1 " type="text" name="filter" onKeyDown ={handleKeyDown} onChange={handleInputChange} />
				     <button onMouseDown={handleAddSearchterm} className="absolute right-0 mr-305 md:mr-8 mt-6 text-white font-bold  focus:outline-none  w-1/12 pb-205" type="button"><MagnifierComp className="text-gray-400 hover:text-gray-300  w-505 ml-auto mr-auto  fill-current"/></button>
				</div>

				<h2 className="mb-6 text-gray-100">Filter</h2>
				<section className="mb-6">
					<h3 className="mb-2 text-gray-100">By Subject Area</h3>
					{subjectbuttons}
				</section>
				
				<section className="mb-6">
					<h3 className="mb-2 text-gray-100">By Language of Publication</h3>
					{languagebuttons}
				</section>

				<section className="mb-6">
					<h3 className="mb-2 text-gray-100">By Year of Publication</h3>
					{yearbuttons}
				</section>
			</div>
		</div>
		)

}

export default SlideIn;