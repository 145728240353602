import React from 'react';
import { Link } from 'react-router-dom';
import Prismic from 'prismic-javascript'
import {linkResolver } from '../prismic-configuration';
import { RichText, Elements } from 'prismic-reactjs';


// Helper function to convert Prismic Rich Text links to React Link components
export const customLink = (type, element, content, children, index) => (
  <Link to={linkResolver(element.data)} key={index} className="border border-red-500 text-blue-500">
    {content} hello
  </Link>
);




	
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};



export const htmlSerializer = function(type, element, content, children, key) {


	var props = {};

	  switch(type) {
	  	case Elements.heading1: // Heading 1
	  	   // props = {className: " pt-405 md:pt-605 mb-504 md:mb-604 "};
	  	props = {className: " "};

      	return React.createElement('h1', propsWithUniqueKey(props, key), children);

      	case Elements.heading2:
      	//props = {className: ' mb-3 mt-4 md:mt-6 md:mb-305 md:text-lgfat'};
      //	props = {className: ''};
      	return React.createElement('h2', propsWithUniqueKey(props, key), children);

      	case Elements.heading3:
      	props = {className: ' text-base md:text-lg font-bold'};
      	return React.createElement('h3', propsWithUniqueKey(props, key), children);
	 
 // Add a class to paragraph elements
    	case Elements.paragraph:
      		props = {className: ' '};
      	return React.createElement('p', propsWithUniqueKey(props, key), children);

	    // Add a class to hyperlinks
	    case Elements.hyperlink:
	    	const targetAttr = element.data.target ? { target: element.data.target } : {};
      		const relAttr = element.data.target ? { rel: 'noopener' } : {};

      		props = Object.assign({
        		className: 'hover:underline hover:text-gray-600',
        		href: element.data.url || linkResolver(element.data)
      			}, targetAttr, relAttr);

      	//props = {className: ' hover:underline hover:text-gray-600'};
	    return React.createElement('a', propsWithUniqueKey(props, key), children);

	

    	case Elements.oList: // Ordered List
	     props = {className: ' text-base md:text-lg md:mb-305 mb-3'};
      return React.createElement('ol', propsWithUniqueKey(props, key), children);

	 
	    // Don't wrap images in a <p> tag
	    case Elements.image:
	      return '<img src="' + element.url + '" alt="' + element.alt + '">';
	 
	    // Return null to stick with the default behavior for all other elements
	    default:
	      return null;
	  }
};